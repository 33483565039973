import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_PATH } from "../../constant";

const Gallery = ({ name, images, brochurURL, videoURL }) => {
  
  const [backgroundImage, setBackgroundImage] = useState(
    images.length > 0 ? images[0].url : ""
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setBackgroundImage(images[next].url),
  };

  useEffect(() => {
    if (images.length > 0) {
      setBackgroundImage(images[0].url);
    }
  }, []);

  const HandleClick = (link) => {
    window.open(`${IMAGE_PATH+link}`, "_blank");
  };

  const HandleClick2 = (link) => {
    window.open(`${link}`, "_blank");
  };

  return (
    <div className=" mt-10 py-14 sm:py-22 px-8 relative w-full max-w-7xl  bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4">
      <div className="mb-6 flex max-w-7xl flex-row justify-between sm:items-center sm:flex-colum">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="text-2xl text-gray-800 font-semibold">
            {name} Gallery
          </h1>
          <div
            style={{
              width: "5vw",
              backgroundColor: "purple",
              height: 3,
              marginTop: 10,
            }}
          ></div>
        </div>
        <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
          <button
          onClick={()=>HandleClick(brochurURL)}
          className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 bg-white px-5 py-3 text-gray-500 transition hover:text-gray-700 focus:outline-none focus:ring"
            type="button"
          >
            <span className="text-sm font-medium">Get Brochure</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </button>
          <button
          onClick={()=>HandleClick2(videoURL)}
            className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 bg-indigo-600 px-5 py-3 text-white transition hover:text-white-700 focus:outline-none focus:ring"
            type="button"
          >
            <span className="text-sm font-medium">Get Video</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        className="relative w-full max-w-7xl h-[650px] bg-cover border border-gray-300 rounded-md shadow-md bg-white py-4"
        style={{
          backgroundImage: `url(${IMAGE_PATH + backgroundImage})`,
        }}
      >
        <div className="absolute bottom-4 left-8 w-full max-w-lg">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div
                key={index}
                className={`px-2 ${index === 1 ? "transform scale-110" : ""}`}
              >
                <img
                  src={IMAGE_PATH + image.url}
                  alt={`Property ${index}`}
                  className="w-full h-auto rounded-lg"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
