import React, { useState, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";

import PropertyCard from "./PropertyCard";
import SearchBarWithDropdown from "./DropdownStates";
import TopNavBar from "../NavBar";
import Footer from "../Footer";
import assetContext from "../../context/AssetsContext/AssetContext";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination";
import ChooseCityModal from "../ChooseCity";
import LeadRegister2 from "./LeadRegister2";

const PropertyList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

 
  const [query, setQuery] = useState(queryParams.get("property_name"));
  const [filters, setFilters] = useState({
    property_type: queryParams.get("property_type"),
    property_status: queryParams.get("property_status"),
    property_location: queryParams.get("property_location"),
    property_locality: queryParams.get("property_locality"),
    property_builder: queryParams.get("property_builder"),
    property_bhks: queryParams.get("property_bhks"),
    status: queryParams.get("status"),
    property_starting_price: queryParams.get("property_starting_price"),
    property_maximum_price: queryParams.get("property_maximum_price"),
    is_featured: queryParams.get("is_featured"),
    is_infocused: queryParams.get("is_infocused"),
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [similarAssets, setSimilarAssets] = useState([]);

  const context = useContext(assetContext);
  const { assets, totalAssets, getSearchAssets, getAAsset } = context;

  const handleDataReceived = async (data) => {
    setQuery("" + data.text);

    console.log(data.filters);
    await handleFilters(data.filters);
  };

  const handleFilters = async (filtersx) => {
    getSearchAssets({
      page: currentPage,
      property_type: filtersx !== null ? filtersx.property_type : [],
      property_status: filtersx !== null ? filtersx.property_status : [],
      property_locality: (filtersx !== null && filtersx.property_locality!== undefined)? filtersx.property_locality : [],
      property_location:
        filtersx !== null
          ? filtersx.property_location
          : localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder: filtersx !== null ? filtersx.property_builder : [],
      property_bhks: filtersx !== null ? filtersx.property_bhks : [],
      status: filtersx !== null ? filtersx.status : [],
      property_starting_price: 0,
      property_maximum_price:
        filtersx !== null ? filtersx.property_maximum_price : 15,
      property_name: query,
      is_featured: (filtersx !== null && filtersx.is_featured!== undefined)?  filtersx.is_featured:0,
      is_infocused:(filtersx !== null && filtersx.is_infocused!== undefined)?  filtersx.is_infocused:0
       
    });

    setFilters({
      property_type: filtersx !== null ? filtersx.property_type : [],
      property_status: filtersx !== null ? filtersx.property_status : [],
      property_locality: (filtersx !== null && filtersx.property_locality!== undefined)? filtersx.property_locality : [],
 
      property_location:
        filtersx !== null
          ? filtersx.property_location
          : localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder: filtersx !== null ? filtersx.property_builder : [],
      property_bhks: filtersx !== null ? filtersx.property_bhks : [],
      status: filtersx !== null ? filtersx.status : [],
      property_starting_price: 0,
      property_maximum_price:
        filtersx !== null ? filtersx.property_maximum_price : 15,
        is_featured: (filtersx !== null && filtersx.is_featured!== undefined)?  filtersx.is_featured:0,
      is_infocused:(filtersx !== null && filtersx.is_infocused!== undefined)?  filtersx.is_infocused:0
       
    });

    const queryParams = new URLSearchParams({
      page: 1,
      property_type:
        filtersx.property_type !== null ? filtersx.property_type : [],
      property_status:
        filtersx.property_status !== null ? filtersx.property_status : [],
        property_locality: (filtersx !== null && filtersx.property_locality!== undefined)? filtersx.property_locality : [],

      property_location:
        filtersx.property_location !== null
          ? filtersx.property_location
          : localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder:
        filtersx.property_builder !== null ? filtersx.property_builder : [],
      property_bhks:
        filtersx.property_bhks !== null ? filtersx.property_bhks : [],
      status: filtersx.status !== null ? filtersx.status : [],
      property_starting_price: 0,
      property_maximum_price:
        filtersx.property_maximum_price !== null
          ? filtersx.property_maximum_price
          : 15,
      property_name: query,
      is_featured: (filtersx !== null && filtersx.is_featured!== undefined)?  filtersx.is_featured:0,
      is_infocused:(filtersx !== null && filtersx.is_infocused!== undefined)?  filtersx.is_infocused:0
       
    }).toString();

    navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (filters !== null) {
      getSearchAssets({
        page: newPage,
        property_type: filters !== null ? filters.property_type : [],
        property_status: filters !== null ? filters.property_status : [],
        property_location: filters !== null ? filters.property_location : [],
        property_locality: (filters !== null && filters.property_locality!== undefined)? filters.property_locality : [],
 
        property_builder: filters !== null ? filters.property_builder : [],
        property_bhks: filters !== null ? filters.property_bhks : [],
        status: filters !== null ? filters.status : [],
        property_starting_price:
          filters !== null ? filters.property_starting_price : 0,
        property_maximum_price:
          filters !== null ? filters.property_maximum_price : 10,
        property_name: query,
        is_featured: (filters !== null && filters.is_featured!== undefined)?  filters.is_featured:0,
      is_infocused:(filters !== null && filters.is_infocused!== undefined)?  filters.is_infocused:0
       
      });
      
    } else {
    }
    // Additional logic such as fetching data for the new page
  };

  const [showModal, setShowModal] = useState(false);

  const handleSelectCity = (selectedCity) => {
    localStorage.setItem("city", selectedCity);
    window.location.reload();

    setShowModal(false);
  };

  useEffect(() => {
    const city = localStorage.getItem("city");
    if (city === null || city === "") {
      setShowModal(true);
    }
  }, [localStorage.getItem("city")]);

  useEffect(() => {
    handleFilters(filters);
  }, []);
  
  
  useEffect(() => {
    setSimilarAssets(assets);
    
  }, [assets]); 

  const HandleClick = (id, slug) => {
    getAAsset({ slug: slug });
    window.open(
      `/${localStorage.getItem("city")}/property/${slug}`,
      "_blank"
    );
    // newTab.addEventListener('load', () => {
    //   newTab.postMessage(JSON.stringify(assets), '*');
    // });
    localStorage.setItem("projectData", JSON.stringify(assets));

    // navigate(`/${localStorage.getItem("city")}/property/${id}/${name}`);
  };

  return (
    <>
      <div>
        <div className="fixed top-0 bg-grey w-full z-50">
          <TopNavBar />
        </div>
        <div
          style={{
            display: "flex",
            maxWidth: "100%",
            justifyContent: "center",
            backgroundColor: "#F7F7F7",
            margin: "70px 0px",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width: "50vw",
              // height: 250,
              padding: 10,

              // backgroundColor:"black",
            }}
          >
            <div style={{ borderRadius: 6 }} className="sticky top-16 z-30">
              <SearchBarWithDropdown
                onDataReceived={handleDataReceived}
                style={{ width: "100%", backgroundColor: "black" }}
              />
            </div>

            <ChooseCityModal
              show={showModal}
              onClose={() => setShowModal(false)}
              onSelect={handleSelectCity}
            />

    

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  marginLeft: 15,
                }}
              >
                Showing {assets.length} out of {totalAssets} from  {localStorage.getItem("city")}
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(totalAssets / 10)}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </div>
            </div>
            <div className="">
              
              <div className="space-y-4 p-4">
                {assets.map((item, index) => (
                  <div key={index}>
                    <PropertyCard
                      isColumn={false}
                      project={item}
                      isShowDetails={true}
                      isShowDesc={true}
                      similarProjects={assets}
                    />
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    marginLeft: 15,
                  }}
                >
                  Showing {assets.length} out of {totalAssets}
                </div>
                <div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(totalAssets / 10)}
                    onPageChange={(page) => handlePageChange(page)}
                  />
                </div>
              </div>
            </div>
          </div>

          <LeadRegister2 isMargin={true} />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default PropertyList;
