import React from "react";

import HomeLoan from "../../src/assets/icons/home-loan.png";
import HomeInterior from "../../src/assets/icons/home-interior.png";
import PropertyLegalService from "../../src/assets/icons/propery-legal-services.png";
import PropertyManagement from "../../src/assets/icons/property-management.png";
import Escrow from "../../src/assets/icons/escrow-services.png";
import OnlineRentAgreement from "../../src/assets/icons/online-rent-agreement.png"; 
import RentReceiptGenerator from "../../src/assets/icons/online-rent-receipt-generator.png";
import SellOrRentProperty from "../../src/assets/icons/sell-or-rent-property.png";
import Titlesearch from "../../src/assets/icons/title-search.png";
import Litigation from "../../src/assets/icons/litigation.png";
import Valuation from "../../src/assets/icons/valuation.png";
import PropertyRegistration from "../../src/assets/icons/property-registration.png";

import LeadRegister3 from "./Property/LeadRegister3";

const InHouseServicess = () => {
  return (
    <>
      <section className="bg-gray-900 text-white">
        <div className="bg-transparent mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="bg-transparent mx-auto max-w-lg text-center">
            <h2 className="bg-transparent text-3xl font-bold sm:text-4xl">
              In-House Services
            </h2>

            <p className="bg-transparent mt-4 text-gray-300">
              Your one stop real-estate partner is just around the corner.
              Explore our real-estate property solutions now
            </p>
          </div>

          <div className="bg-transparent mt-8 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-6">
            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={HomeLoan}
                alt="Error"
                className="bg-transparent"
              />

              <LeadRegister3 title="Home Loan" />
            </div>
            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={HomeInterior}
                alt="Error"
                className="bg-transparent"
              />

              <LeadRegister3 title="Home Interior" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={Escrow}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Escrow Service" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={Titlesearch}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Title Search" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={Litigation}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Litigation" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={Valuation}
                alt="Error"
                className="bg-transparent"
              />

              <LeadRegister3 title="Valuation" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={PropertyLegalService}
                alt="Error"
                className="bg-transparent"
              />

              <LeadRegister3 title="Property Legal Service" />
            </div>
            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={PropertyManagement}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Property Management" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={OnlineRentAgreement}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Online Rent Agreement" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={RentReceiptGenerator}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Rent Receipt Generator" />

              <h2 className="bg-transparent mt-4 text-center    text-black"></h2>
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={SellOrRentProperty}
                alt="Error"
                className="bg-transparent"
              />

              <LeadRegister3 title="Sell or Rent Property" />
            </div>

            <div
              style={{ height: 150, backgroundColor: "white" }}
              className="flex flex-col justify-center items-center bg-grey block rounded-xl align border border-grey-800 p-8 shadow-xl transition hover:border-red-900/10 hover:shadow-red-500/10"
            >
              <img
                style={{ width: 60 }}
                src={PropertyRegistration}
                alt="Error"
                className="bg-transparent"
              />
              <LeadRegister3 title="Property Registration" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InHouseServicess;
